import React, { useContext } from 'react'
import { FaPowerOff, FaUser } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import AuthContext from '../../Store/auth-context'
import styles from './MainHeader.module.css'

const MainHeader = () => {
    const authCtx = useContext(AuthContext)
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.logoWrapper}>
                <Link to="/">
                    <img src="https://stylelink.s3.ap-south-1.amazonaws.com/qnnox-assets/master-file-Qnnox-logo_512x512.png" />

                </Link>
            </div>
            <div className={styles.rightSectionWrapper}>
                <div className={styles.userIconWrapper}>
                    <FaUser />
                </div>
                <div className={styles.loginBtnWrapper}>
                    <button className={styles.userIconWrapper} onClick={() => authCtx.logout()}>
                        <FaPowerOff />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default MainHeader