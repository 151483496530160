import React, { useContext } from 'react'
import { Routes, Route } from "react-router-dom";
import MainFooter from '../Components/Footers/MainFooter';
import LeftHeader from '../Components/Headers/LeftHeader';
import MainHeader from '../Components/Headers/MainHeader';
import AnalysisHome from '../Pages/Analysis/AnalysisHome';
import Login from '../Pages/Authentications/Login';
import Invoices from '../Pages/Billings/Invoices';
import MainHome from '../Pages/Home/MainHome';
import GettingStarted from '../Pages/Informatives/GettingStarted';
import GSTDetails from '../Pages/User/GSTDetails';
import UserData from '../Pages/User/UserData';
import AuthContext from '../Store/auth-context';
import styles from './MainNavigator.module.css'

const MainNavigator = () => {
    const authCtx = useContext(AuthContext)
    const isLoggedIn = authCtx?.isLoggedIn

    if (!isLoggedIn) {
        return (
            <div>
                <Routes>
                    <Route path="*" element={<Login />}></Route>
                    <Route path="/" element={<Login />}></Route>
                    <Route path="/login" element={<Login />}></Route>
                </Routes>
            </div>
        )
    } else {
        return (
            <div className={styles.mainWrapper}>
                <MainHeader />
                <div className={styles.mainBodyWrapper}>
                    <div className={styles.mainLeftWrapper}>
                        <LeftHeader />
                    </div>
                    <div className={styles.mainRightWrapper}>
                        <Routes>
                            <Route path="*" element={<MainHome />}></Route>
                            <Route path="/" element={<MainHome />}></Route>
                            <Route path="/home" element={<MainHome />}></Route>
                            <Route path="/user-data" element={<UserData />}></Route>
                            <Route path="/getting-started" element={<GettingStarted />}></Route>
                            <Route path="/analysis" element={<AnalysisHome />}></Route>
                            {/* adding this on 2nd June 2024 */}
                            <Route path="/gst-details" element={<GSTDetails />}></Route>
                            <Route path="/invoice" element={<Invoices />}></Route>
                            
                        </Routes>
                    </div>
                </div>
                <MainFooter />
            </div>
        )
    }
}

export default MainNavigator